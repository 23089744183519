import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import './index.css';
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import App from './App';
import Firebase, { FirebaseContext } from './components/Firebase';

let theme = createMuiTheme({
  typography: {
    h1: { fontSize: '3rem' },
    h2: { fontSize: '2.1rem' },
    h3: { fontSize: '1.4rem' }
  }
});

theme.typography.h1.marginBottom = theme.spacing(2);

theme = responsiveFontSizes(theme, { factor: 2 });

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const config = {
  api_key: process.env.REACT_APP_HONEYBADGER_KEY,
  environment: process.env.REACT_APP_ENVIRONMENT,
  revision: process.env.REACT_APP_SHA
};

const honeybadger = Honeybadger.configure(config);

ReactDOM.render(
  <HoneybadgerErrorBoundary honeybadger={honeybadger}>
    <FirebaseContext.Provider value={new Firebase()}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={3}
          action={(key) => <Button onClick={onClickDismiss(key)}>Close</Button>}
        >
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </FirebaseContext.Provider>
  </HoneybadgerErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

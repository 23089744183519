import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Firebase, { withFirebase } from '../Firebase';

const StudentRequestListItem = ({ firebase, student }) => {
  const handleOnClick = () => {
    firebase.resetStudent(student.uid);
    firebase.logEvent('reset_student');
  };

  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={`${student.firstName} ${student.lastName}`}
          secondary={student.carId ? `Car Id: ${student.carId}` : ''}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={handleOnClick}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider component="li" />
    </>
  );
};

StudentRequestListItem.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  student: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    carId: PropTypes.number,
    uid: PropTypes.string.isRequired
  }).isRequired
};

export default withFirebase(StudentRequestListItem);

import React from 'react';
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';

const NavList = (props) => {
  const { mobile, toggleFunction, children, className } = props;

  if (mobile) {
    return (
      <List data-type="mobile" className={className} onClick={toggleFunction}>
        {children}
      </List>
    );
  }

  return (
    <List data-type="desktop" className={className}>
      {children}
    </List>
  );
};

NavList.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  mobile: PropTypes.bool,
  toggleFunction: PropTypes.func
};

NavList.defaultProps = {
  className: null,
  mobile: false,
  toggleFunction: null
};

export default NavList;

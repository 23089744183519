import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Home from './components/Home/Home';
import ManageStudents from './components/ManageStudents/ManageStudents';
import BulkAddPage from './components/ManageStudents/BulkAddPage';
import StudentRequest from './components/StudentRequest/StudentRequestPage';
import StudentRelease from './components/StudentRelease/StudentReleasePage';
import ManageUsers from './components/ManageUsers/ManageUsers';
import Reset from './components/Reset/ResetPage';
import './App.css';
import Navbar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import * as ROUTES from './constants/routes';
import SignInScreen from './components/SignIn/SignInScreen';
import NoAccount from './components/Session/NoAccountPage';
import NotAuthorized from './components/Session/NotAuthorizedPage';
import { withAuthentication } from './components/Session';

const styles = (theme) => ({
  root: {
    display: 'flex'
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
});

const App = (props) => {
  const { classes, error, loading } = props;

  if (loading || error) {
    return loading ? <LinearProgress /> : error.message;
  }

  return (
    <BrowserRouter>
      <div className={classes.root}>
        <CssBaseline />
        <Navbar />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route exact path={ROUTES.LANDING} component={Home} />
            <Route
              exact
              path={ROUTES.STUDENT_REQUEST}
              component={StudentRequest}
            />
            <Route
              exact
              path={ROUTES.STUDENT_RELEASE}
              component={StudentRelease}
            />
            <Route exact path={ROUTES.SIGN_IN} component={SignInScreen} />
            <Route
              exact
              path={ROUTES.MANAGE_STUDENTS}
              component={ManageStudents}
            />
            <Route exact path={ROUTES.BULK_ADD} component={BulkAddPage} />
            <Route exact path={ROUTES.MANAGE_USERS} component={ManageUsers} />
            <Route exact path={ROUTES.RESET_DISMISSAL} component={Reset} />
            <Route exact path={ROUTES.USER_NOT_MANAGED} component={NoAccount} />
            <Route
              exact
              path={ROUTES.USER_NOT_AUTHORIZED}
              component={NotAuthorized}
            />
          </Switch>
          <Footer />
        </main>
      </div>
    </BrowserRouter>
  );
};

App.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  classes: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired
};

export default compose(withStyles(styles), withAuthentication)(App);

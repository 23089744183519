import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Firebase, { withFirebase } from '../Firebase';

const DeleteStudentButtonAndModal = ({ firebase, student }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  });

  const handleClose = useCallback(() => {
    setOpen(false);
  });

  const handleCloseAndDelete = useCallback(() => {
    setOpen(false);
    firebase.deleteStudent(student.uid).catch((error) => {
      // eslint-disable-next-line no-console
      console.log({ error });
    });
  });

  return (
    <div>
      <IconButton edge="end" aria-label="delete" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        className="delete-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Delete ${student.firstName} ${student.lastName}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will permanently delete {student.firstName} {student.lastName}
            from the system.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancel-button"
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className="delete-button"
            onClick={handleCloseAndDelete}
            color="secondary"
            autoFocus
          >
            Delete {student.firstName}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeleteStudentButtonAndModal.propTypes = {
  // eslint-disable-next-line react/require-default-props
  firebase: PropTypes.instanceOf(Firebase),
  student: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    carId: PropTypes.number,
    uid: PropTypes.string.isRequired
  }).isRequired
};

export default withFirebase(DeleteStudentButtonAndModal);

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withSnackbar } from 'notistack';
import Firebase, { withFirebase } from '../Firebase';

const BulkAddDialog = ({ firebase, data, open, openFunc, enqueueSnackbar }) => {
  const [snackSuccessOpen, setSnackSuccessOpen] = useState(false);
  const [snackFailureOpen, setSnackFailureOpen] = useState(false);

  const handleModalClose = useCallback(() => {
    openFunc(false);
  });

  const handleCloseAndAdd = useCallback(() => {
    openFunc(false);

    firebase.addStudents(data).then(
      (res) => {
        setSnackSuccessOpen(true);
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setSnackFailureOpen(true);
      }
    );
  });

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (snackFailureOpen) {
      enqueueSnackbar('Something is wrong, please try again!', {
        variant: 'error'
      });
    }

    if (snackSuccessOpen) {
      enqueueSnackbar('Uploaded successfully', { variant: 'success' });
    }
  });

  return (
    <div>
      <Dialog
        id="add-student-confirm"
        open={open}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Create {data.length} students?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will create {data.length} students in the system.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-test="create"
            onClick={handleCloseAndAdd}
            color="primary"
            autoFocus
          >
            Create Students
          </Button>
          <Button
            data-test="cancel"
            onClick={handleModalClose}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

BulkAddDialog.propTypes = {
  // eslint-disable-next-line react/require-default-props
  firebase: PropTypes.instanceOf(Firebase),
  enqueueSnackbar: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      carId: PropTypes.number
    })
  ).isRequired,
  open: PropTypes.bool.isRequired,
  openFunc: PropTypes.func.isRequired
};

export default compose(withFirebase, withSnackbar)(BulkAddDialog);

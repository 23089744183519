import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import StudentRequestListItem from './StudentRequestListItem';

const StudentRequestActiveList = ({ students }) => {
  return (
    <div id="active-list-content">
      <Typography variant="h2">Active requests</Typography>
      <List>
        {students.map((student) => (
          <StudentRequestListItem key={student.uid} student={student} />
        ))}
      </List>
    </div>
  );
};

StudentRequestActiveList.propTypes = {
  students: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      carId: PropTypes.number
    })
  ).isRequired
};

export default StudentRequestActiveList;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import StudentRequestAutosuggest from './StudentRequestAutosuggest';
import Firebase, { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import StudentRequestActiveList from './StudentRequestActiveList';
import StudentReleasedList from '../SharedList/StudentReleasedList';
import { isUser } from '../../util/claims';

const useStyles = makeStyles({
  root: {
    padding: 0
  }
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  const classes = useStyles();

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      <Box p={3} mt={2} className={classes.root}>
        {children}
      </Box>
    </Typography>
  );
};

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  index: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
};

const RequestStudents = ({ firebase }) => {
  const [requested, setRequested] = useState([]);
  const [released, setReleased] = useState([]);
  const [availableStudents, setAvailableStudents] = useState([]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Requested student state.
  useEffect(() => {
    const unsubscribe = () => {
      firebase.dismissalRequests().onSnapshot((snapshot) => {
        const students = [];
        snapshot.forEach((doc) =>
          students.push({ ...doc.data(), uid: doc.id })
        );
        setRequested(students);
      });
    };
    return unsubscribe();
  }, [firebase]);

  // Released student state
  useEffect(() => {
    const unsubscribe = () => {
      firebase.dismissalReleases().onSnapshot((snapshot) => {
        const students = [];
        snapshot.forEach((doc) =>
          students.push({ ...doc.data(), uid: doc.id })
        );
        setReleased(students);
      });
    };
    return unsubscribe();
  }, [firebase]);

  // All non released/requested students state
  useEffect(() => {
    const unsubscribe = () => {
      firebase.dismissalAllAvailable().onSnapshot((snapshot) => {
        const students = [];
        snapshot.forEach((doc) =>
          students.push({ ...doc.data(), uid: doc.id })
        );
        setAvailableStudents(students);
      });
    };
    return unsubscribe();
  }, [firebase]);

  return (
    <div>
      <Helmet>
        <title>Request students</title>
      </Helmet>
      <Typography variant="h1">Request students</Typography>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        aria-label="student request options"
      >
        <Tab
          label="Request a student"
          data-test="request-tab"
          {...a11yProps(0)}
        />
        <Tab label="Active requests" data-test="active-tab" {...a11yProps(1)} />
        <Tab
          label="Released students"
          data-test="released-tab"
          {...a11yProps(2)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <StudentRequestAutosuggest students={availableStudents} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <StudentRequestActiveList students={requested} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <StudentReleasedList students={released} />
      </TabPanel>
    </div>
  );
};

RequestStudents.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired
};

const condition = (authUser) => !!authUser && isUser(authUser.claims);

export default compose(
  withAuthorization(condition),
  withFirebase
)(RequestStudents);

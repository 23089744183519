import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import Firebase, { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import StudentReleaseActiveList from './StudentReleaseActiveList';
import StudentReleasedList from '../SharedList/StudentReleasedList';
import { isUser } from '../../util/claims';

const RequestStudents = ({ firebase }) => {
  const [requested, setRequested] = useState([]);
  const [released, setReleased] = useState([]);

  // Requested student state.
  useEffect(() => {
    const unsubscribe = () => {
      firebase.dismissalRequests().onSnapshot((snapshot) => {
        const students = [];
        snapshot.forEach((doc) =>
          students.push({ ...doc.data(), uid: doc.id })
        );
        setRequested(students);
      });
    };
    return unsubscribe();
  }, [firebase]);

  // Released student state
  useEffect(() => {
    const unsubscribe = () => {
      firebase.dismissalReleases().onSnapshot((snapshot) => {
        const students = [];
        snapshot.forEach((doc) =>
          students.push({ ...doc.data(), uid: doc.id })
        );
        setReleased(students);
      });
    };
    return unsubscribe();
  }, [firebase]);

  return (
    <div>
      <Helmet>
        <title>Release students</title>
      </Helmet>
      <Typography variant="h1">Release students</Typography>
      <div>
        <StudentReleaseActiveList students={requested} />
        <StudentReleasedList students={released} />
      </div>
    </div>
  );
};

RequestStudents.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired
};

const condition = (authUser) => !!authUser && isUser(authUser.claims);

export default compose(
  withAuthorization(condition),
  withFirebase
)(RequestStudents);

import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import StudentReleaseListItem from './StudentReleaseListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2)
  }
}));

const StudentRequestActiveList = ({ students }) => {
  const classes = useStyles();

  return (
    <div id="active-list" className={classes.root}>
      <Typography variant="h2">Active requests</Typography>
      <List>
        {students.map((student) => (
          <StudentReleaseListItem key={student.uid} student={student} />
        ))}
      </List>
    </div>
  );
};

StudentRequestActiveList.propTypes = {
  students: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      carId: PropTypes.number
    })
  ).isRequired
};

export default StudentRequestActiveList;

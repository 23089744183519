import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4)
  },
  int: {
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  copyright: {
    float: 'right'
  }
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.int} variant="body2">
        <Link href="https://dismissly.com/legal/terms-of-use">
          Terms of Use
        </Link>
        <Link href="https://dismissly.com/legal/privacy-policy">
          Privacy Policy
        </Link>
        <Link href="https://dismissly.com/legal/terms-of-license">
          Terms of License
        </Link>
        <span className={classes.copyright}>Copyright Droplet, LLC</span>
      </Typography>
    </div>
  );
};

export default Footer;

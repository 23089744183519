import React from 'react';
import PropTypes from 'prop-types';
import AuthUserContext from './context';
import Firebase, { withFirebase } from '../Firebase';

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        authUser: null,
        loading: true,
        error: ''
      };
    }

    componentDidMount() {
      const { firebase } = this.props;

      this.listener = firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          firebase
            .getClaims()
            .then((claims) => {
              if (claims) {
                // eslint-disable-next-line no-param-reassign
                authUser.claims = claims;
              }
              this.setState({ authUser, loading: false });
            })
            .catch((error) => {
              this.setState({ error: error.message, loading: false });
            });
        } else {
          this.setState({ authUser: null, loading: false });
        }
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      const { authUser, loading, error } = this.state;
      return (
        <AuthUserContext.Provider value={authUser}>
          {/* eslint-disable react/jsx-props-no-spreading */}
          <Component loading={loading} error={error} {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  WithAuthentication.propTypes = {
    /* eslint-disable react/forbid-prop-types */
    authUser: PropTypes.object,
    firebase: PropTypes.instanceOf(Firebase).isRequired
  };

  WithAuthentication.defaultProps = {
    authUser: null
  };

  return withFirebase(WithAuthentication);
};

export default withAuthentication;

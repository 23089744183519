const suggestionsFilter = (inputValue, item) => {
  const lower = inputValue.trim().toLowerCase();

  return (
    !inputValue ||
    item.firstName.toLowerCase().includes(lower) ||
    item.lastName.toLowerCase().includes(lower) ||
    (item.carId ? item.carId.toString() === lower : false)
  );
};

export default suggestionsFilter;

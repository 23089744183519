const formatStudentDocument = (data) => {
  const schema = {
    firstName: data.firstName,
    lastName: data.lastName,
    requested: false,
    released: false
  };

  if (data.carId) {
    schema.carId = parseInt(data.carId, 10);
  }

  if (typeof schema.firstName === 'undefined') {
    throw new ReferenceError('firstName is not defined');
  }

  if (typeof schema.lastName === 'undefined') {
    throw new ReferenceError('lastName is not defined');
  }

  // eslint-disable-next-line no-restricted-globals
  if (schema.carId && isNaN(schema.carId)) {
    throw new ReferenceError('CarId must be an integer');
  }

  return schema;
};

export default formatStudentDocument;

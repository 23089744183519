import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import HomeIcon from '@material-ui/icons/Home';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import SchoolIcon from '@material-ui/icons/School';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import RestoreIcon from '@material-ui/icons/Restore';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as ROUTES from '../../constants/routes';
import ListItemLink from '../ListItemLink';
import UserInfo from '../Firebase/UserInfo';
import NavList from './NavList';
import { isUser, isCoordinator, isAdmin } from '../../util/claims';
import AuthUserContext from '../Session/context';

const showUser = (authUser) => !!authUser && isUser(authUser.claims);
const showCoordinator = (authUser) =>
  !!authUser && isCoordinator(authUser.claims);
const showAdmin = (authUser) => !!authUser && isAdmin(authUser.claims);

function ResponsiveDrawer(props) {
  let drawerWidth = 240;

  if (useContext(AuthUserContext) === null) {
    drawerWidth = 0;
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex'
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    appBar: {
      marginLeft: drawerWidth,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    title: {
      flexGrow: 1
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    }
  }));

  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  });

  const drawer = (authUser, mobile, toggleFunction) =>
    showUser(authUser) ? (
      <div>
        <div />
        <Divider />
        <NavList
          className="standard-nav"
          mobile={mobile}
          toggleFunction={toggleFunction}
        >
          <ListItemLink
            to={ROUTES.LANDING}
            primary="Home"
            icon={<HomeIcon />}
          />
          <ListItemLink
            to={ROUTES.STUDENT_REQUEST}
            primary="Request Students"
            icon={<DirectionsCarIcon />}
          />
          <ListItemLink
            to={ROUTES.STUDENT_RELEASE}
            primary="Release Students"
            icon={<SchoolIcon />}
          />
        </NavList>
        {showCoordinator(authUser) ? (
          <>
            <Divider className="nav-divider" />
            <NavList
              className="advanced-nav"
              mobile={mobile}
              toggleFunction={toggleFunction}
            >
              <ListItemLink
                to={ROUTES.RESET_DISMISSAL}
                primary="Reset Dismissal"
                icon={<RestoreIcon />}
              />
              {showAdmin(authUser) ? (
                <>
                  <ListItemLink
                    to={ROUTES.MANAGE_STUDENTS}
                    primary="Manage Students"
                    icon={<SettingsIcon />}
                  />
                  <ListItemLink
                    to={ROUTES.BULK_ADD}
                    primary="Bulk Upload"
                    icon={<CloudUploadIcon />}
                  />
                  <ListItemLink
                    to={ROUTES.MANAGE_USERS}
                    primary="Manage Users"
                    icon={<PersonAddIcon />}
                  />
                </>
              ) : null}
            </NavList>
          </>
        ) : null}
      </div>
    ) : null;

  return (
    <AuthUserContext.Consumer>
      {/* eslint-disable react/jsx-props-no-spreading */}
      {(authUser) => (
        <>
          <AppBar id="app-bar" position="fixed" className={classes.appBar}>
            <Toolbar>
              {showUser(authUser) ? (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              ) : null}
              <Typography className={classes.title} variant="h6" noWrap>
                Dismissly
              </Typography>
              <UserInfo />
            </Toolbar>
          </AppBar>
          {showUser(authUser) ? (
            <nav className={classes.drawer} aria-label="dismissal navigation">
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden smUp implementation="css">
                <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper
                  }}
                  ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                  }}
                >
                  {drawer(authUser, true, handleDrawerToggle)}
                </Drawer>
              </Hidden>
              <Hidden xsDown implementation="css">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper
                  }}
                  variant="permanent"
                  open
                >
                  {drawer(authUser, false)}
                </Drawer>
              </Hidden>
            </nav>
          ) : null}
        </>
      )}
    </AuthUserContext.Consumer>
  );
}

ResponsiveDrawer.propTypes = {
  /* eslint-disable react/require-default-props */
  container: PropTypes.node
};

export default ResponsiveDrawer;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import BulkAddForm from './BulkAddForm';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

const BulkAddDropzone = ({ enqueueSnackbar }) => {
  const [file, setFile] = useState('');
  const [header, setHeader] = useState(true);
  const [error, setError] = useState('');

  const fileInput = React.createRef();

  const handleHeaderChange = (event) => {
    setHeader(event.target.checked);
  };

  const isFile = (fileObj) => {
    if (fileObj.current.children[0].files.length === 0) {
      setError('No file selected');
      return false;
    }

    return true;
  };

  const isCsv = (fileObj) => {
    if (
      fileObj.current.children[0].files[0].type === 'text/csv' ||
      fileObj.current.children[0].files[0].name.endsWith('.csv')
    ) {
      return true;
    }

    setError('Only CSV files are allowed');
    return false;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isFile(fileInput) && isCsv(fileInput)) {
      setFile(fileInput.current.children[0].files[0]);
    }
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  });

  const classes = useStyles();

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Input
          id="file-upload"
          className={classes.textField}
          label="CSV Upload"
          type="file"
          ref={fileInput}
          inputProps={{ accept: '.csv, text/csv' }}
        />
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
        >
          Upload
        </Button>
      </form>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={header}
              onChange={handleHeaderChange}
              value="headerRow"
            />
          }
          label="File contains a header row"
        />
      </FormGroup>
      {file ? <BulkAddForm file={file} header={header} /> : ''}
    </>
  );
};

BulkAddDropzone.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired
};

export default withSnackbar(BulkAddDropzone);

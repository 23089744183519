import validator from 'email-validator';

export const isEmpty = (value) => {
  return value === '' || value === undefined;
};

export const isNotValid = (email) => {
  const valid = validator.validate(email);
  return !valid;
};

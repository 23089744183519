import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import { withAuthorization } from '../Session';
import Firebase, { withFirebase } from '../Firebase';
import AddStudentForm from './AddStudentForm';
import StudentList from './StudentList';
import StudentDataConfirmation from '../Disclosure/StudentDataConfirmation';
import { isAdmin } from '../../util/claims';

class ManageStudents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      students: []
    };
  }

  componentDidMount() {
    const { firebase } = this.props;
    this.setState({ loading: true });

    this.unsubscribe = firebase.students().onSnapshot((snapshot) => {
      const students = [];
      snapshot.forEach((doc) => students.push({ ...doc.data(), uid: doc.id }));
      this.setState({
        students,
        loading: false
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { students, loading } = this.state;
    return (
      <div id="manage-students">
        <Helmet>
          <title>Manage students</title>
        </Helmet>
        <Typography variant="h1">Manage students</Typography>
        <AddStudentForm />
        {loading && <div>Loading ...</div>}
        <StudentList students={students} />
        <StudentDataConfirmation />
      </div>
    );
  }
}

ManageStudents.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired
};

const condition = (authUser) => !!authUser && isAdmin(authUser.claims);

export default compose(
  withAuthorization(condition),
  withFirebase
)(ManageStudents);

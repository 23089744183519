export const LANDING = '/';
export const SIGN_IN = '/signin';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const MANAGE_STUDENTS = '/manage/students';
export const MANAGE_USERS = '/manage/users';
export const BULK_ADD = '/manage/students/bulk';
export const STUDENT_REQUEST = '/students/request';
export const STUDENT_RELEASE = '/students/release';
export const USER_NOT_MANAGED = '/no-account-found';
export const USER_NOT_AUTHORIZED = '/not-authorized';
export const RESET_DISMISSAL = '/students/reset';

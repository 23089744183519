import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import ResetButton from './ResetButton';
import { isCoordinator } from '../../util/claims';

const ManageStudents = () => {
  return (
    <div id="reset-content">
      <Helmet>
        <title>Reset dismissal</title>
      </Helmet>
      <Typography variant="h1">Reset dismissal</Typography>
      <Typography>
        Reset all requested and released states. This cannot be undone!
      </Typography>
      <Typography>
        We recommend assigning one person to do this each day.
      </Typography>
      <ResetButton />
    </div>
  );
};

const condition = (authUser) => !!authUser && isCoordinator(authUser.claims);

export default compose(
  withAuthorization(condition),
  withFirebase
)(ManageStudents);

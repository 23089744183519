import * as CONFIG from '../constants/config';

const isUser = (claims) => {
  if (claims === null || claims === undefined) {
    return false;
  }

  // Checking attribute.
  if (!(typeof claims === 'object') || Object.keys(claims).length === 0) {
    return false;
  }

  // Allow super user.
  if (claims.email === CONFIG.ADMIN_ACCOUNT) {
    return true;
  }

  if (claims.role === undefined) {
    return false;
  }

  return (
    claims.role === 'user' ||
    claims.role === 'coordinator' ||
    claims.role === 'administrator'
  );
};

const isCoordinator = (claims) => {
  if (claims === null || claims === undefined) {
    return false;
  }

  // Checking attribute.
  if (!(typeof claims === 'object') || Object.keys(claims).length === 0) {
    return false;
  }

  // Allow super user.
  if (claims.email === CONFIG.ADMIN_ACCOUNT) {
    return true;
  }

  if (claims.role === undefined) {
    return false;
  }

  return claims.role === 'coordinator' || claims.role === 'administrator';
};

const isAdmin = (claims) => {
  if (claims === null || claims === undefined) {
    return false;
  }

  if (!(typeof claims === 'object') || Object.keys(claims).length === 0) {
    return false;
  }

  // Allow super user.
  if (claims.email === CONFIG.ADMIN_ACCOUNT) {
    return true;
  }

  return claims.role === 'administrator';
};

const isManagedUser = (claims) => {
  if (claims === null || claims === undefined) {
    return false;
  }

  if (!(typeof claims === 'object') || Object.keys(claims).length === 0) {
    return false;
  }

  // Allow super user.
  if (claims.email === CONFIG.ADMIN_ACCOUNT) {
    return true;
  }

  return !!claims.managed;
};

export { isUser, isCoordinator, isAdmin, isManagedUser };

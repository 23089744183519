import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Firebase, { withFirebase } from './index';

const SignInButton = ({ firebase }) => {
  return (
    <Button
      data-cy="signin"
      color="inherit"
      edge="end"
      onClick={firebase.doSignIn}
    >
      Sign In
    </Button>
  );
};

SignInButton.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired
};

export default withFirebase(SignInButton);

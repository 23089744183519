import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import Firebase, { withFirebase } from '../Firebase';
import tableIcons from './UserTableIcons';
import { isEmpty, isNotValid } from './utils';

const UserTable = ({ users, firebase, enqueueSnackbar }) => {
  return (
    <MaterialTable
      columns={[
        {
          title: 'First Name',
          field: 'firstName',
          validate: (rowData) => !isEmpty(rowData.firstName)
        },
        {
          title: 'Last Name',
          field: 'lastName',
          validate: (rowData) => !isEmpty(rowData.lastName)
        },
        {
          title: 'Email Address',
          field: 'email',
          editable: 'onAdd',
          validate: (rowData) => !isNotValid(rowData.email)
        },
        {
          title: 'Role',
          field: 'role',
          lookup: {
            user: 'User',
            coordinator: 'Dismissal Coordinator',
            administrator: 'Administrator'
          },
          initialEditValue: 'user'
        }
      ]}
      data={users}
      title="Users"
      icons={tableIcons}
      editable={{
        onRowAddCancelled: () =>
          enqueueSnackbar('Cancelled adding new user', { variant: 'info' }),
        onRowUpdateCancelled: () =>
          enqueueSnackbar('Cancelled updating account', {
            variant: 'info'
          }),
        onRowAdd: (newData) => {
          return firebase
            .addUser(newData)
            .then(() => {
              enqueueSnackbar(
                `Added account for ${newData.firstName} ${newData.lastName}`,
                { variant: 'success' }
              );
            })
            .catch(() => {
              enqueueSnackbar('An error occurred, please try again', {
                variant: 'error'
              });
            });
        },
        onRowUpdate: (newData) => {
          return firebase
            .editUser(newData)
            .then((userAcct) => {
              return firebase.user(userAcct.data.uid).get();
            })
            .then((user) => {
              const data = user.data();
              enqueueSnackbar(
                `Updated account for ${data.firstName} ${data.lastName}`,
                { variant: 'success' }
              );
            })
            .catch(() => {
              enqueueSnackbar('An error occurred, please try again', {
                variant: 'error'
              });
            });
        },
        onRowDelete: (data) => {
          return firebase
            .deleteUser(data.uid)
            .then(() => {
              enqueueSnackbar(
                `Deleted account for ${data.firstName} ${data.lastName}`,
                { variant: 'success' }
              );
            })
            .catch(() => {
              enqueueSnackbar('An error occurred, please try again', {
                variant: 'error'
              });
            });
        }
      }}
    />
  );
};

UserTable.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired
    })
  ).isRequired
};

export default compose(withFirebase, withSnackbar)(UserTable);

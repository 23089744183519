import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import SignInButton from '../Firebase/SignInButton';
import Firebase, { withFirebase } from '../Firebase/index';
import * as ROUTES from '../../constants/routes';

const SignInScreen = ({ firebase, history }) => {
  useEffect(() => {
    const unsubscribe = () => {
      firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          history.push(ROUTES.LANDING);
        }
      });
    };
    return unsubscribe();
  }, [firebase.auth, history]);

  return (
    <div>
      <h1>Dismissly</h1>
      <p>Please sign-in:</p>
      <SignInButton />
    </div>
  );
};

SignInScreen.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  firebase: PropTypes.instanceOf(Firebase).isRequired
};

export default withFirebase(SignInScreen);

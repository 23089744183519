import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withSnackbar } from 'notistack';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Firebase, { withFirebase } from '../Firebase';

const StudentRequestListItem = ({ firebase, student, enqueueSnackbar }) => {
  const handleSendOnClick = () => {
    firebase.addRelease(student.uid).then(
      (res) => {
        enqueueSnackbar(`${student.firstName} ${student.lastName} released`, {
          variant: 'success'
        });
        firebase.logEvent('release_student');
      },
      (err) => {
        enqueueSnackbar(
          `Error releasing ${student.firstName} ${student.lastName}`,
          {
            variant: 'error'
          }
        );
      }
    );
  };

  const handleResetOnClick = () => {
    firebase.resetStudent(student.uid).then(
      (res) => {
        enqueueSnackbar(`${student.firstName} ${student.lastName} removed`, {
          variant: 'info'
        });
        firebase.logEvent('reset_student');
      },
      (err) => {
        enqueueSnackbar(
          `Error removing ${student.firstName} ${student.lastName}`,
          {
            variant: 'error'
          }
        );
      }
    );
  };

  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={`${student.firstName} ${student.lastName}`}
          secondary={student.carId ? `Car Id: ${student.carId}` : ''}
        />
        <ListItemSecondaryAction>
          <Button aria-label="send" onClick={handleSendOnClick}>
            Release
          </Button>
          <IconButton
            edge="end"
            aria-label="reset"
            onClick={handleResetOnClick}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider component="li" />
    </>
  );
};

StudentRequestListItem.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  student: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    carId: PropTypes.number,
    uid: PropTypes.string.isRequired
  }).isRequired
};

export default compose(withFirebase, withSnackbar)(StudentRequestListItem);

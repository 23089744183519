import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import '../Firebase';
import { withAuthorization } from '../Session';
import { isUser } from '../../util/claims';

const useStyles = makeStyles({
  card: {
    minWidth: 275
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});

// The usage of React.forwardRef will no longer be required for react-router-dom v6.
// see https://github.com/ReactTraining/react-router/issues/6056
// eslint-disable-next-line react/jsx-props-no-spreading
const AdapterLink = React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Link innerRef={ref} {...props} />
));

const Home = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>Dismissly</title>
      </Helmet>
      <Typography variant="h1">Welcome!</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card data-test="request-students" className={classes.card}>
            <CardContent>
              <Typography variant="h2">Request Students</Typography>
              <Typography variant="body2" component="p">
                For staff out in the pick up lane.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                component={AdapterLink}
                to="/students/request"
              >
                Manage Requests
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card data-test="release-students" className={classes.card}>
            <CardContent>
              <Typography variant="h2">Release Students</Typography>
              <Typography variant="body2" component="p">
                For staff with the students.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                component={AdapterLink}
                to="/students/release"
              >
                Manage Releases
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

const condition = (authUser) => !!authUser && isUser(authUser.claims);

export default withAuthorization(condition)(Home);

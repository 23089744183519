import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import { withAuthorization } from '../Session';
import Firebase, { withFirebase } from '../Firebase';
import UserTable from './UserTable';
import { isAdmin } from '../../util/claims';

const ManageUsers = ({ firebase }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const unsubscribe = () => {
      firebase.users().onSnapshot((snapshot) => {
        const items = [];
        snapshot.forEach((doc) => items.push({ ...doc.data(), uid: doc.id }));
        setUsers(items);
      });
    };
    return unsubscribe();
  }, [firebase]);

  return (
    <div id="manage-users">
      <Helmet>
        <title>Manage users</title>
      </Helmet>
      <Typography variant="h1">Manage users</Typography>
      <Typography variant="h2">User roles</Typography>
      <ul>
        <li>
          <strong>User:</strong> Can request and release students.
        </li>
        <li>
          <strong>Dismissal Coordinator:</strong> Can reset dismissal in
          addition to User access.
        </li>
        <li>
          <strong>Administrator:</strong> Can add and remove students from the
          system, manage user access, in addition to Dismissal Coordinator
          access.
        </li>
      </ul>
      <UserTable users={users} />
    </div>
  );
};

ManageUsers.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired
};

const condition = (authUser) => !!authUser && isAdmin(authUser.claims);

export default compose(withAuthorization(condition), withFirebase)(ManageUsers);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Papa from 'papaparse';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import BulkAddDialog from './BulkAddDialog';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

const BulkAddForm = ({ file, header }) => {
  const [fileName, setFileName] = useState('');
  const [fileData, setFileData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const manageState = (result, fileObject) => {
    setFileName(fileObject.name);
    setFileData(result.data);
  };

  const openModal = (modalState) => {
    setModalOpen(modalState);
  };

  const openModalFromButton = () => {
    openModal(true);
  };

  useEffect(() => {
    // Parse the data.
    Papa.parse(file, {
      complete: manageState,
      header,
      dynamicTyping: true,
      skipEmptyLines: true
    });
  }, [file, header]);

  const classes = useStyles();

  return (
    <>
      <Typography data-test="records">
        {fileData.length} number of records in {fileName}
      </Typography>
      <Button
        id="add-student"
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={openModalFromButton}
      >
        Add {fileData.length} Students
      </Button>
      <BulkAddDialog data={fileData} open={modalOpen} openFunc={openModal} />
    </>
  );
};

BulkAddForm.propTypes = {
  file: PropTypes.instanceOf(File).isRequired,
  header: PropTypes.bool.isRequired
};

export default BulkAddForm;

import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import Firebase, { withFirebase } from '../Firebase';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1)
  }
}));

const ResetButton = ({ firebase, enqueueSnackbar }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [resetCount, setResetCount] = React.useState(0);
  const [resetEmpty, setResetEmpty] = React.useState(false);

  const handleClickOpen = useCallback(() => {
    setOpenModal(true);
  });

  const handleClose = useCallback(() => {
    setOpenModal(false);
  });

  const handleCloseAndReset = useCallback(() => {
    setOpenModal(false);

    firebase.resetStudents().then(
      (res) => {
        console.log(res, 'res');
        if (res.data.size === 0) {
          setResetEmpty(true);
        } else {
          setResetCount(res.data.size);
        }
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.log('Error getting documents', err);
        setResetEmpty(true);
      }
    );
  });

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (resetEmpty) {
      enqueueSnackbar('Nothing to reset', { variant: 'error' });
    }

    if (resetCount) {
      enqueueSnackbar(`Reset ${resetCount} students`, { variant: 'success' });
    }
  });

  const classes = useStyles();

  return (
    <>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        onClick={handleClickOpen}
      >
        Reset Dismissal
      </Button>
      <Dialog
        id="reset-dialog"
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Reset Dismissal?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will reset all of the student request and release states in the
            system.{' '}
          </DialogContentText>
          <DialogContentText>Are you sure?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-test="cancel" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            data-test="reset"
            onClick={handleCloseAndReset}
            color="secondary"
            autoFocus
          >
            Reset Dismissal
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ResetButton.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

export default compose(withFirebase, withSnackbar)(ResetButton);

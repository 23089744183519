import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';
import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import StudentDataConfirmation from '../Disclosure/StudentDataConfirmation';
import { isAdmin } from '../../util/claims';
import BulkAddDropzone from './BulkAddDropzone';

const BulkAddPage = () => {
  return (
    <div id="bulk-add">
      <Helmet>
        <title>Add multiple students</title>
      </Helmet>
      <Typography variant="h1">Add multiple students</Typography>
      <Typography>
        You may upload in bulk a file of student names and, optionally, CarID
        numbers.
      </Typography>
      <Typography variant="h2">Getting started</Typography>
      <Typography>
        NOTE: you may upload a maximum of 500 students at one time. In order to
        upload more than that, please split your student list into multiple
        files.
      </Typography>
      <ol>
        <li>
          <Link id="template-link" href="/templates/student-template.csv">
            Download the template
          </Link>
        </li>
        <li>
          Add your students, one per row. Please make sure to keep the first row
          (column headings) intact
        </li>
        <li>Save the file as a CSV format</li>
        <li>Return to this page and upload your file(s)</li>
        <li>
          Validate that your upload was successful by visiting the{' '}
          <Link href="./">Manage Students</Link> page and viewing your student
          list
        </li>
      </ol>

      <BulkAddDropzone />
      <StudentDataConfirmation />
    </div>
  );
};

const condition = (authUser) => !!authUser && isAdmin(authUser.claims);

export default compose(withAuthorization(condition), withFirebase)(BulkAddPage);

import app from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import * as CONFIG from '../../constants/config';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    const shouldUseEmulator = window.location.hostname === 'localhost'; // or other logic to determine when to use

    const firestoreSettings = {};
    // Pass long polling setting to Firestore when running in Cypress
    if (window.Cypress) {
      // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
      firestoreSettings.experimentalForceLongPolling = true;
    }

    // Emulate Firestore
    if (shouldUseEmulator) {
      firestoreSettings.host = process.env.REACT_APP_FIRESTORE_EMULATOR_HOST;
      firestoreSettings.ssl = false;

      app.firestore().settings(firestoreSettings);
    }

    this.fieldValue = app.firestore.FieldValue;

    this.auth = app.auth();

    if (shouldUseEmulator) {
      this.auth.useEmulator('http://localhost:9099');
    }

    this.db = app.firestore();
    this.timeStamp = app.firestore.FieldValue.serverTimestamp();
    this.deleteField = app.firestore.FieldValue.delete();
    this.functions = app.functions();

    // Emulate Cloud Functions
    if (shouldUseEmulator) {
      this.functions.useFunctionsEmulator('http://localhost:5001');
    }

    this.analytics = app.analytics();

    this.provider = new app.auth.GoogleAuthProvider();

    this.provider.setCustomParameters({
      prompt: 'select_account'
    });
  }

  logEvent = (event) => {
    this.analytics.logEvent(event);
  };

  doSignOut = () => {
    this.auth.signOut();
  };

  doSignIn = () => {
    this.auth.signInWithPopup(this.provider);
  };

  getProfilePicUrl = () => {
    return this.auth.currentUser.photoURL || '/images/profile_placeholder.png';
  };

  getUserName = () => {
    return this.auth.currentUser.displayName;
  };

  getEmail = () => {
    return this.auth.currentUser.email;
  };

  getClaims = async () => {
    let idTokenResult;
    try {
      idTokenResult = await this.auth.currentUser.getIdTokenResult(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    return idTokenResult.claims;
  };

  isManagedUser = (claims) => {
    // Allow super user.
    if (this.getEmail() === CONFIG.ADMIN_ACCOUNT) {
      return true;
    }
    return !!claims.managed;
  };

  isUserSignedIn = () => {
    return !!this.auth().currentUser;
  };

  user = (uid) => this.db.doc(`users/${uid}`);

  users = () =>
    this.db.collection('users').orderBy('lastName').orderBy('firstName');

  addUser = (data) => {
    return this.functions
      .httpsCallable('addManagedUser')(data)
      .catch((error) => {
        throw new Error(error);
      });
  };

  editUser = (data) => {
    return this.functions
      .httpsCallable('editUser')(data)
      .then((user) => {
        return user;
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  deleteUser = (uid) => {
    // This deletes the user account and associated firestore record.
    return this.functions
      .httpsCallable('deleteUser')(uid)
      .catch((error) => {
        throw new Error(error);
      });
  };

  // Student Management API
  student = (uid) => this.db.doc(`students/${uid}`);

  students = () =>
    this.db.collection('students').orderBy('lastName').orderBy('firstName');

  addStudent = (fields) => this.db.collection('students').add(fields);

  addStudents = async (data) => {
    return this.functions
      .httpsCallable('addStudents')(data)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  deleteStudent = (uid) => this.db.collection('students').doc(uid).delete();

  // Dismissal Management API
  addRequest = async (uid) => {
    this.db
      .collection('students')
      .doc(uid)
      .update({ requested: true, requestedAt: this.timeStamp });
  };

  addRelease = async (uid) => {
    this.db
      .collection('students')
      .doc(uid)
      .update({ released: true, releasedAt: this.timeStamp });
  };

  resetStudent = async (uid) => {
    await this.db.collection('students').doc(uid).update({
      requested: false,
      released: false,
      requestedAt: this.deleteField,
      releasedAt: this.deleteField
    });
  };

  resetStudents = async () => {
    return this.functions
      .httpsCallable('resetStudents')()
      .catch((error) => {
        throw new Error(error);
      });
  };

  // Students that have been requested or released, by nature of 'requested' set to true, which is the start of the process.
  dismissals = () =>
    this.db.collection('students').where('requested', '==', true);

  // Students that have been requested but not released.
  dismissalRequests = () =>
    this.db
      .collection('students')
      .where('requested', '==', true)
      .where('released', '==', false)
      .orderBy('requestedAt');

  // Students that have been released.
  dismissalReleases = () =>
    this.db
      .collection('students')
      .where('released', '==', true)
      .orderBy('lastName')
      .orderBy('firstName');

  // Students that have not been requested or released.
  dismissalAllAvailable = () =>
    this.db
      .collection('students')
      .where('requested', '==', false)
      .where('released', '==', false)
      .orderBy('lastName')
      .orderBy('firstName');
}
export default Firebase;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import StudentRequestAutosuggestListItem from './StudentRequestAutosuggestListItem';
import suggestionsFilter from '../../util/suggestionsFilter';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

const StudentRequestAutosuggest = ({ students }) => {
  const classes = useStyles();

  const preventResetOnBlur = (state, changes) => {
    if (changes.type === Downshift.stateChangeTypes.blurInput) {
      return {}; // no-changes
    }
    return changes;
  };

  return (
    <Downshift
      itemToString={(item) => (item ? item.firstName : '')}
      stateReducer={preventResetOnBlur}
    >
      {({
        getInputProps,
        getItemProps,
        clearSelection,
        getMenuProps,
        isOpen,
        inputValue
      }) => {
        const getAdornments = () => {
          if (inputValue) {
            return {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={clearSelection}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )
            };
          }
          return {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          };
        };

        return (
          <div>
            <TextField
              id="searchbox"
              label="Search by name or car id"
              fullWidth
              className={classes.textField}
              margin="normal"
              variant="outlined"
              InputProps={getAdornments()}
              {...getInputProps()}
            />
            <List {...getMenuProps()}>
              {isOpen
                ? students
                    .filter((item) => suggestionsFilter(inputValue, item))
                    .map((item, index) => (
                      <StudentRequestAutosuggestListItem
                        student={item}
                        {...getItemProps({
                          key: item.uid,
                          index,
                          item
                        })}
                      />
                    ))
                : null}
            </List>
          </div>
        );
      }}
    </Downshift>
  );
};

StudentRequestAutosuggest.propTypes = {
  students: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      carId: PropTypes.number
    })
  ).isRequired
};

export default StudentRequestAutosuggest;

import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import StudentReleasedListItem from './StudentReleasedListItem';

const StudentRequestReleasedList = ({ students }) => {
  return (
    <div id="student-list-content">
      <Typography variant="h2">Released</Typography>
      <List>
        {students.map((student) => (
          <StudentReleasedListItem key={student.uid} student={student} />
        ))}
      </List>
    </div>
  );
};

StudentRequestReleasedList.propTypes = {
  students: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      carId: PropTypes.number
    })
  ).isRequired
};

export default StudentRequestReleasedList;

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import studentSchema from '../../util/studentSchema';
import Firebase, { withFirebase } from '../Firebase';

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  carId: '',
  error: null
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(1)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}));

const AddStudentForm = (props) => {
  const [values, setValues] = React.useState(INITIAL_STATE);

  const onSubmit = (event) => {
    const { firebase } = props;

    firebase
      .addStudent(studentSchema(values))
      .then(() => {
        setValues({ ...INITIAL_STATE });
      })
      .catch((error) => {
        setValues({ error });
      });
    event.preventDefault();
  };

  const onChange = (event) => {
    setValues({ ...values, [event.target.id]: event.target.value });
  };

  const isInvalid = (firstName, lastName) => {
    return firstName === '' || lastName === '';
  };

  const classes = useStyles();

  return (
    <div id="add-student" className={classes.root}>
      <Typography variant="h2">Add a student</Typography>
      <form onSubmit={onSubmit} autoComplete="off">
        <TextField
          id="firstName"
          className={classes.textField}
          label="First Name"
          value={values.firstName}
          onChange={onChange}
          margin="normal"
        />
        <TextField
          id="lastName"
          className={classes.textField}
          label="Last Name"
          value={values.lastName}
          onChange={onChange}
          margin="normal"
        />
        <TextField
          id="carId"
          className={classes.textField}
          label="Car Id"
          value={values.carId}
          type="number"
          onChange={onChange}
          margin="normal"
        />
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={isInvalid(values.firstName, values.lastName)}
          type="submit"
        >
          Add Student
        </Button>
        {values.error && <p>{values.error.message}</p>}
      </form>
    </div>
  );
};

AddStudentForm.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired
};

export default withFirebase(AddStudentForm);

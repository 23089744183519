import React from 'react';

const FirebaseContext = React.createContext(null);

export const withFirebase = (Component) => (props) =>
  (
    <FirebaseContext.Consumer>
      {(firebase) => (
        /* eslint-disable react/jsx-props-no-spreading */
        <Component {...props} firebase={firebase} />
      )}
    </FirebaseContext.Consumer>
  );

export default FirebaseContext;

import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import AuthUserContext from './context';
import Firebase, { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      const authUser = this.context;
      const { firebase, history } = this.props;
      // If not autheticated at all, go to sign in.
      if (authUser === null) {
        history.push(ROUTES.SIGN_IN);
      }
      // If we're not a managed account.
      else if (!firebase.isManagedUser(authUser.claims)) {
        history.push(ROUTES.USER_NOT_MANAGED);
      }
      // If we don't match the specified condition for the page.
      else if (!condition(authUser)) {
        history.push(ROUTES.USER_NOT_AUTHORIZED);
      }
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {/* eslint-disable react/jsx-props-no-spreading */}
          {(authUser) =>
            condition(authUser) ? <Component {...this.props} /> : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  WithAuthorization.displayName = `withAuthorization(${Component.name})`;

  WithAuthorization.contextType = AuthUserContext;

  WithAuthorization.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    firebase: PropTypes.instanceOf(Firebase).isRequired
  };

  return compose(withRouter, withFirebase)(WithAuthorization);
};

export default withAuthorization;

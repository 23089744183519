import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';

import StudentListItem from './StudentListItem';

const StudentList = ({ students }) => {
  return (
    <div id="student-list">
      <Typography variant="h2">All students</Typography>
      <List>
        {students.map((student) => (
          <StudentListItem key={student.uid} student={student} />
        ))}
      </List>
    </div>
  );
};

StudentList.propTypes = {
  students: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      carId: PropTypes.number
    })
  ).isRequired
};

export default StudentList;

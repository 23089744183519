import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteStudentButtonAndModal from './DeleteStudentButtonAndModal';

const StudentListItem = ({ student }) => {
  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={`${student.lastName}, ${student.firstName}`}
          secondary={student.carId ? `Car Id: ${student.carId}` : ''}
        />
        <ListItemSecondaryAction>
          <DeleteStudentButtonAndModal student={student} />
        </ListItemSecondaryAction>
      </ListItem>
      <Divider component="li" />
    </>
  );
};

StudentListItem.propTypes = {
  student: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    carId: PropTypes.number
  }).isRequired
};

export default StudentListItem;

import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

export default function StudentDataConfirmation() {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      id="ferpa-dialog"
      onClose={handleClose}
      aria-labelledby="ferpa-title"
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="ferpa-title">REMINDER</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please provide <em>only</em> data that has been properly designated as
          Directory Information under FERPA. It is a violation of the Site Terms
          to provide any information about a student to Droplet, either through
          the Site or the Services or otherwise, that is not properly designated
          Directory Information under FERPA.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          I understand
        </Button>
      </DialogActions>
    </Dialog>
  );
}

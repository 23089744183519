import React from 'react';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import { isManagedUser } from '../../util/claims';
import { withAuthorization } from '.';

const NotAuthorized = () => {
  return (
    <div>
      <Helmet>
        <title>Not authorized</title>
      </Helmet>
      <Typography variant="h1">Not authorized</Typography>
      <Typography>
        Your account is not authorized to access that page. Please contact your
        school administrator for access.
      </Typography>
    </div>
  );
};

const condition = (authUser) => !!authUser && isManagedUser(authUser.claims);

export default withAuthorization(condition)(NotAuthorized);

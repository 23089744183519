import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';

const StudentReleasedListItem = ({ student }) => {
  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={`${student.lastName}, ${student.firstName}`}
          secondary={student.carId ? `Car Id: ${student.carId}` : ''}
        />
      </ListItem>
      <Divider component="li" />
    </>
  );
};

StudentReleasedListItem.propTypes = {
  student: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    carId: PropTypes.number,
    uid: PropTypes.string.isRequired
  }).isRequired
};

export default StudentReleasedListItem;

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withSnackbar } from 'notistack';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Firebase, { withFirebase } from '../Firebase';

const StudentRequestListItem = ({ firebase, student, enqueueSnackbar }) => {
  const handleOnClick = (event) => {
    firebase.addRequest(student.uid).then(
      (res) => {
        enqueueSnackbar(`${student.firstName} ${student.lastName} requested`, {
          variant: 'success'
        });
        firebase.logEvent('request_student');
      },
      (err) => {
        enqueueSnackbar(
          `Error requesting ${student.firstName} ${student.lastName}`,
          {
            variant: 'error'
          }
        );
      }
    );

    event.preventDefault();
  };

  return (
    <>
      <ListItem button alignItems="flex-start" onClick={handleOnClick}>
        <ListItemText
          primary={`${student.firstName} ${student.lastName}`}
          secondary={student.carId ? `Car Id: ${student.carId}` : ''}
        />
        <ListItemSecondaryAction />
      </ListItem>
      <Divider component="li" />
    </>
  );
};

StudentRequestListItem.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  student: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    carId: PropTypes.number,
    uid: PropTypes.string.isRequired
  }).isRequired
};

export default compose(withFirebase, withSnackbar)(StudentRequestListItem);

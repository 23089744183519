import React from 'react';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import { isManagedUser } from '../../util/claims';
import { withAuthorization } from '.';

const NoAccountPage = () => {
  return (
    <div>
      <Helmet>
        <title>No account found</title>
      </Helmet>
      <Typography variant="h1">No account found</Typography>
      <Typography>
        Please contact your school administrator for access.
      </Typography>
    </div>
  );
};

const condition = (authUser) => !!authUser && !isManagedUser(authUser.claims);

export default withAuthorization(condition)(NoAccountPage);
